<template>
  <div class="connent_title">
    <div class="connent_header">
      <span>行为日志</span>
    </div>
  </div>

  <el-form :inline="true" class="demo-form-inline">
    <el-form-item label="创建日期">
      <el-date-picker
          v-model="searchData.createDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="onSearch" v-hasPermission="'/Api/Logs/List'">查询</el-button>
    </el-form-item>
  </el-form>

  <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
    <el-table-column
        v-for="item in tableOptions"
        :key="item.value"
        :label="item.label"
        :prop="item.value">
    </el-table-column>
  </el-table>

  <el-pagination
      background
      :pager-count="11"
      layout="total, prev, pager, next, jumper"
      :total="pageData.total"
      :page-size="pageData.size"
      :current-page="pageData.current"
      @current-change="changePage">
  </el-pagination>

</template>

<script>

import axios from '@/utils/axios'

export default {
  data() {
    return {
      loading: false,
      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {
      },
      // 表格设置
      tableOptions: [{
        value: 'username',
        label: '用户名称',
      }, {
        value: 'action',
        label: '动作名称',
      }, {
        value: 'table_name',
        label: '功能名称',
      }, {
				value: 'tag',
				label: '访问路径',
			}, {
        value: 'createdDate',
        label: '创建时间',
      }],
      // 表格数据
      tableData: []
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    onSearch() {
      this.getTableData()
    },

    // 获取列表
    getTableData() {
      this.loading = true
      var that = this

      if (this.searchData.createDate){
        var _searchData = {
          startDate: this.searchData.createDate[0]+" 00:00:00",
          stopDate: this.searchData.createDate[1]+" 23:59:59",
        }
      }
      axios.get('/Logs/List', {
        params: Object.assign({}, _searchData, this.pageData)
      }).then(res => {
        that.tableData = res.data.list
        that.pageData.total = res.data.total
        that.loading = false
      }).catch(error => {
        that.loading = false
      })
    },

    changePage(page) {
      this.pageData.current = page
      this.getTableData()
    }
  }
}
</script>